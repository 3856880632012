<template>
  <div class="select-container">
    <div class="header">
      <div class="title">渠道店铺</div>
    </div>
    <div class="content">
      <el-input placeholder="店铺名称" v-model="keyword" size="small" :clearable="true" :style="{marginBottom: '12px'}">
        <el-button slot="append" icon="el-icon-search" size="mini" @click="search"></el-button>
      </el-input>
      <div v-loading="loading" class="riderContainer">
        <div v-for="(item) in shopList" :key="item.sid" @click="handleSelectShop(item)" class="item" :style="{color: currentSid===item.rplatShop?'#409EFF':''}">
          {{item.cname}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getStoShopList} from "@/api/delivery";

export default {
  name: "SelectShop",
  props: {
    shopData: {
      type: Object,
      required: true,
      default: null,
    }
  },
  data() {
    return {
      loading:false,
      keyword:'',
      shopList:[],
      allShopList:[],
      currentSid:'',
    };
  },
  created() {
  },
  mounted() {
    this.currentSid = this.shopData?.sid;
    this.getStoShopList();
  },
  methods: {
    getStoShopList() {
      this.loading = true;
      getStoShopList().then(result => {
        if (result) {
          this.shopList = result.data;
          this.allShopList = result.data;
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    search() {
      this.currentSid = '';
      this.shopList = this.allShopList.filter(item => {
        return item['cname'].toLowerCase().includes(this.keyword.toLowerCase());
      });
      if(this.shopList.length > 0){
        this.currentSid = this.shopList[0].rplatShop;
        this.$emit('selectShop', {sid:this.currentSid,cname:this.shopList[0].cname});
      }else{
        this.currentSid = '-1';
        this.$emit('selectShop', {sid:this.currentSid,cname:''});
      }
    },
    handleSelectShop(item) {
      this.currentSid = item.rplatShop;
      this.$emit('selectShop', {sid:item.rplatShop,cname:item.cname});
    },
  },
};
</script>

<style lang="scss" scoped>
.select-container{
  float: left;
  width: 220px;
  height: 100%;
  margin-right: 20px;
  border: 1px solid #ddd;
  .header{
    width: 100%;
    height: 40px;
    line-height: 40px;
    border-bottom:1px solid #ddd;
    padding-left: 15px;
    .title{
      width: 60px;
      height: 100%;
      border-bottom: 2px solid #409EFF;
    }
  }
  .content{
    width: 100%;
    height: calc(100% - 40px);
    padding: 15px 10px;
    .item{
      width: 100%;
      height: auto;
      line-height: 18px;
      font-size: 14px;
      padding-left: 10px;
      margin-bottom: 18px;
      cursor: pointer;
    }
  }
}
.riderContainer{
  width: 100%;
  height: calc(100% - 50px);
  overflow-y: auto;
}
</style>
