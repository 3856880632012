<template>
  <div class="container">
    <SelectShop @selectShop="handleSelectShop" :shopData="shopData"/>
    <div class="right-container">
      <div class="list-container" :style="{height: height}">
        <div class="header">
          <div class="text">{{ shopName }} 渠道运单记录</div>
          <div class="return">
            <el-button type="text" @click="goBack">
              <span class="returnBtn">返回</span>
            </el-button>
          </div>
        </div>
        <div class="toolbar">
          <el-input placeholder="查询关键字" v-model="keyword" :clearable="true" :style="{ width:'250px',marginTop: '15px',float: 'right'}">
            <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
          </el-input>
        </div>
        <el-table :data="ordFreightList" highlight-current-row ref="table" v-loading="loading" :max-height="tableMaxHeight">
          <el-table-column label="序号" type="index" align="center" width="60px" :index="getIndex"></el-table-column>
          <el-table-column label="门店名称" align="center" width="140px" prop="sStore"></el-table-column>
          <el-table-column label="配送单号" align="center" width="135px" prop="cSys"></el-table-column>
          <el-table-column label="配送平台" align="center" width="100px" prop="sPlatform"></el-table-column>
          <el-table-column label="距离" align="center" width="50px" prop="nDistance"></el-table-column>
          <el-table-column label="用时" align="center" width="70px" prop="fDelivery" :formatter="timeFormatter"></el-table-column>
          <el-table-column label="超时" align="center" width="50px" prop="nDistance">
            <template #default="scope">
              {{ getTimeout(scope.row.bTimeout) }}
            </template>
          </el-table-column>
          <el-table-column label="运费" align="center" width="60px" prop="nDelivery" :formatter="priceFormatter"></el-table-column>
          <el-table-column label="小费" align="center" width="60px" prop="nDispatch" :formatter="priceFormatter"></el-table-column>
          <el-table-column label="小计" align="center" width="60px" prop="nTotal" :formatter="priceFormatter"></el-table-column>
          <el-table-column label="完成时间" align="center" width="140px" prop="tComplete" :formatter="dateFormatter"></el-table-column>
          <el-table-column label="下单时间" align="center" width="140px" prop="tCreate" :formatter="dateFormatter"></el-table-column>
          <el-table-column label="接单时间" align="center" width="140px" prop="tReceive" :formatter="dateFormatter"></el-table-column>
          <el-table-column label="接单时间" align="center" width="140px" prop="tArrive" :formatter="dateFormatter"></el-table-column>
          <el-table-column fixed="right" align="center" label="操作" width="70px">
            <template slot-scope="scope">
              <el-button type="text" @click="view(scope.row)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          :layout="pageLayout"
          :total="total"
          :current-page="pageNum"
          :page-sizes="pageSelect"
          :page-size="pageSize"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :style="{marginTop: '15px'}">
        </el-pagination>
        <ViewOrdFreightDetail :visible.sync="showViewOrdFreightDetail" :sid="currentSid" @close="handleCloseViewOrdFreightDetail"/>
      </div>
    </div>
  </div>
</template>

<script>
import {getOrdFreightList} from "@/api/delivery";
import {timeoutMap} from "@/utils/deliveryMap";
import {formatSeconds, formatTime} from "@/utils/utils";
import ViewOrdFreightDetail from "@/views/components/DeliveryOrder/ViewOrdFreightDetail";
import SelectShop from "@/views/components/SelectShop";

export default {
  name: "ShopOrderDetail",
  components: {SelectShop,ViewOrdFreightDetail},
  props: {
    shopData: {
      type: Object,
      required: true,
      default: null,
    }
  },
  data() {
    return {
      height: document.documentElement.clientHeight - 60 + "px",
      tableMaxHeight: document.documentElement.clientHeight - 250,
      rShop:null,
      shopName:'',
      currentSid:'',
      showViewOrdFreightDetail: false,
      // rStores:[],
      keyword:'',
      ordFreightList:[],
      loading:false,
      total: 0, //总条数
      pageNum: 1, //当前页
      pageSize: 10, //每页显示条数
      pageSelect: [10, 20, 30, 50],
      pageLayout:"prev, pager, next,jumper,total,sizes",
    };
  },
  created() {
  },
  mounted() {
    this.rShop = this.shopData.sid;
    this.shopName = this.shopData.cname;
    this.getOrdFreightList();
  },
  computed: {
    getTimeout() {
      return function(bTimeout) {
        return timeoutMap[bTimeout] || '未知';
      };
    },
  },
  methods: {
    getOrdFreightList() {
      let params = {
        search:this.keyword,
        // rStores:this.rStores,
        pageNum:this.pageNum,
        pageSize:this.pageSize,
        rShop:this.rShop,
      }
      this.loading = true;
      getOrdFreightList(params).then(result => {
        if (result) {
          this.ordFreightList = result.rows;
          this.total = result.total;
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    // 查询
    search(){
      this.pageNum = 1;
      this.getOrdFreightList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
      this.getOrdFreightList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getOrdFreightList();
    },
    handleSelectShop(e) {
      this.rShop = e.sid;
      this.shopName = e.cname;
      this.getOrdFreightList();
    },
    view(item){
      this.currentSid = item.sid;
      this.showViewOrdFreightDetail = true;
    },
    handleCloseViewOrdFreightDetail() {
      this.showViewOrdFreightDetail = false;
    },
    getIndex(rowIndex) {
      return (this.pageNum - 1) * this.pageSize + rowIndex +1;
    },
    priceFormatter(row, column, cellValue) {
      return cellValue?'￥'+cellValue:'';
    },
    timeFormatter(row, column, cellValue) {
      return formatSeconds(cellValue);
    },
    dateFormatter(row, column, cellValue) {
      return cellValue?formatTime(cellValue,"YYYY-MM-DD HH:mm"):'';
    },
    goBack(){
      this.$emit('close');
    }
  },
};
</script>

<style lang="scss" scoped>
.container{
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 20px;
}
.right-container{
  float:left;
  width:calc(100% - 240px);
  height: 100%;
}
.list-container{
  width: 100%;
  border: 1px solid #ddd;
  padding: 0px 20px;
  .header{
    width: 100%;
    height: 50px;
    line-height: 50px;
    padding-left: 5px;
    border-bottom: 1px solid #ddd;
    .text{
      float: left;
      font-size: 16px;
    }
    .return{
      float: right;
    }
  }
  .toolbar{
    width: 100%;
    height: 60px;
  }
}
.returnBtn{
  font-size: 15px;
}
</style>
