var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "select-container" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c(
          "el-input",
          {
            style: { marginBottom: "12px" },
            attrs: { placeholder: "店铺名称", size: "small", clearable: true },
            model: {
              value: _vm.keyword,
              callback: function ($$v) {
                _vm.keyword = $$v
              },
              expression: "keyword",
            },
          },
          [
            _c("el-button", {
              attrs: { slot: "append", icon: "el-icon-search", size: "mini" },
              on: { click: _vm.search },
              slot: "append",
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticClass: "riderContainer",
          },
          _vm._l(_vm.shopList, function (item) {
            return _c(
              "div",
              {
                key: item.sid,
                staticClass: "item",
                style: {
                  color: _vm.currentSid === item.rplatShop ? "#409EFF" : "",
                },
                on: {
                  click: function ($event) {
                    return _vm.handleSelectShop(item)
                  },
                },
              },
              [_vm._v(" " + _vm._s(item.cname) + " ")]
            )
          }),
          0
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "title" }, [_vm._v("渠道店铺")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }